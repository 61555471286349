import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import Api from "../../../../../../../../../api/Api";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import { TagDto } from "../../../../../../../../../api/blog/tag/tag.dto";
import { ToastsStore } from "react-toasts";
import BreakTitle from "../../../../../../../../components/BreakTitle/BreakTitle";
import CheckBoxInput from "../../../../../../../../../libs/forms/CheckBox/CheckBox";

interface TagEditFormProps {
  data: TagDto | undefined;
}

const TagEditForm = (props: TagEditFormProps) => {
  const { data } = props;
  // const { refetch } = useQueryRefetch();
  const { call, status: updateStatus } = useQuery<void, TagDto>("tag/update", [Api.Blog.Tag.Update], { callOnLoad: false });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<TagDto>({ defaultValues: { ...data } });

  const onSubmit = async (dto: TagDto) => {
    call({ ...dto, id: data?.id } as TagDto);
  };

  useEffect(() => {
    reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Zapisano");
      // refetch(Number(id));
      // refetch("tag/get");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
        <TextInput
          id="name"
          label="Nazwa"
          validation_msg={errors?.name?.message}
          required
          inputProps={{
            ...register("name", Validators.required),
          }}
        />
        <TextInput
          id="slug"
          label="Slug"
          validation_msg={errors?.slug?.message}
          required
          inputProps={{
            ...register("slug", Validators.required),
          }}
        />
        <CheckBoxInput
          id="isPublic"
          label="Widoczność"
          description="Publiczny"
          validation_msg={errors?.isPublic?.message}
          inputProps={{
            ...register("isPublic"),
          }}
        />
        <TextInput
          id="description"
          label="Krótki opis ogólny"
          validation_msg={errors?.description?.message}
          inputProps={{
            type: "textarea",
            ...register("description"),
          }}
        />
        <TextInput
          id="tileDescription"
          label="Opis kafelka"
          validation_msg={errors?.description?.message}
          inputProps={{
            type: "textarea",
            ...register("tileDescription"),
          }}
        />
        <BreakTitle className="pl-0">SEO Tags</BreakTitle>
        <TextInput
          id="meta_description"
          label="[SEO] Tag <meta name=“description”>"
          showCharCount
          validation_msg={errors?.meta_description?.message}
          inputProps={{
            type: "textarea",
            ...register("meta_description"),
          }}
        />
        <TextInput
          id="meta_title"
          label="[SEO] Tag <title>"
          showCharCount
          validation_msg={errors?.meta_title?.message}
          inputProps={{
            ...register("meta_title"),
          }}
        />
        <TextInput
          id="redirectUrl"
          label="[SEO] Przekierowanie 308 (url)"
          validation_msg={errors?.redirectUrl?.message}
          inputProps={{
            placeholder:"https://wybieramprojekt.pl/...",
            ...register("redirectUrl"),
          }}
        />
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
          Zapisz
        </LoadingBtn>
      </Form>
    </>
  );
};

export default TagEditForm;
